<template>

    <v-menu
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
    >
        <template v-slot:activator="{ on, attrs }">
        <v-btn 
            icon 
            v-bind="attrs"
            v-on="on"
            >
            <v-icon>mdi-account-outline</v-icon>
        </v-btn> 
        </template>

        <v-card>
        <v-list>
            <v-list-item>
            <v-list-item-avatar>
                <v-icon x-large>mdi-face</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title>{{user}}</v-list-item-title>
                <v-list-item-subtitle>{{email}}</v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
            <v-list-item-group color="primary">
                <v-list-item>
                <v-list-item-title @click="openProfile()">{{$t("message.usermenu.profile")}}</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                <v-list-item-title @click="logout()">{{$t("message.usermenu.logout")}}</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>

        </v-card>
    </v-menu>

</template>


<script>
  import userMixin from '@/mixins/components/core/AppBarMenuUser.js'

  export default {
    name: 'AppBarMenuUser',
    
    mixins: [userMixin]
  }

  
</script>